.login-popup{
	position: fixed!important;
	width: 100%!important;
	height: 100%!important;
	z-index: 1!important;
	background-color: #000000de!important;
	display: grid!important;
	/* margin-top: -2rem !important; */
  /* padding-top: 2rem; */
}
.login-popup-container{
	place-self: center!important;
	width: max(23vw,330px)!important;
	color: #808080!important;
	background-color: #fff!important;
	display: flex!important;
	flex-direction: column!important;
	gap: 25px!important;
	padding: 25px 30px!important;
	border-radius: 8px!important;
	font-size: 40px!important;
	animation: fadeIn 0.5s!important;
}
.login-popup-title{
	display: flex!important;
	justify-content: space-between!important;
	align-items: center!important;
	color: #000!important;
}
.login-popup-title img{
	width: 16px!important;
	cursor: pointer!important;
}
.login-popup-inputs{
	display: flex!important;
	flex-direction: column!important;
	gap: 20px!important;
}
.login-popup-inputs input{
	    font-size: 1rem;
	outline: none!important;
	border: 1px solid #c9c9c9!important;
	padding: 10px!important;
	border-radius: 4px!important;
	height: 3rem;
}
.login-popup-container button{
	border: none!important;
	padding: 10px!important;
	border-radius: 4px!important;
	color: #fff!important;
	/* background-color: tomato!important; */
	background: var(--color-primary);
	font-size: 15px!important;
	cursor: pointer!important;
}
.login-popup-condition{
	display: flex!important;
	align-items: start!important;
	gap: 8px!important;
	margin-top: -15px!important;
}
.login-popup-condition input{
	margin-top: 5px!important;

}
.continuing{
	font-size: 17px!important;
	color: #000 !important;
}
.login-popup-title h2{
	font-size: 30px!important;
	color: #000 !important;
}
.continuing span{
	color: var(--color-primary)!important;
	font-weight: 500!important;
	cursor: pointer!important;
}