 .about__story{
	margin-top: 2rem;
 }
 .about__section-image{
	border-radius: 50%;
	overflow: hidden;
	transform: skew(15deg);
	transition: var(--transition);
	width: 60%;
 }
 #ourstory{
	width: 62%;
 }
 /* .mission-image{
	width: 74%!important;
 } */
 .about__section-image:nth-child(2){
	transform: skew(-15deg);
 }
 .about__section-image:hover{
	transform: skew(0);
 }
.about__section-content h1{
	margin-bottom: 2rem;
}
.about__section-content p{
	margin-bottom: 1rem;
}
.about_story-container,.about_mission-container{
	display: grid;
	grid-template-columns: 40% 50%;
	gap: 10%;
}
.about_Vision-container{
	display: grid;
	grid-template-columns: 50% 40%;
	gap: 10%;
}

.hr{
	margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: var(--color-primary) solid;
    opacity: 0.10;
}
@media screen and (max-width:1024px) {
	.about__section-image{
		width: 60%;
	}
	.about__section-content h1{
		margin-bottom: 1.2rem;
	}
	.about_mission-container,.about_story-container,.about_Vision-container{
		grid-template-columns: 1fr;
		gap: 3rem;
	}
	.about__Vision.about__section-image{
		grid-row: 1;
	}
	
}
@media screen and (max-width:600px){
	 .about__section-image{
		width: 80%;
		margin-inline: auto;
	 }
.about_mission-container,.about_story-container,.about_Vision-container{
	gap: 2rem;
}

}